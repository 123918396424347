import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {
          Object.keys(window)
              .filter(key => key.toLowerCase().indexOf('phantom') !== -1 )
              .map(key => <div key={key}>{key}</div>
        )}
        {window.phantom && JSON.stringify(window.phantom)}
      </header>
    </div>
  );
}

export default App;
